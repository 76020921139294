import React from "react"
import TutorialLayout from "../../components/TutorialLayout"
import START from "../../images/Start.svg"

const Start = () => (
  <TutorialLayout
    imageSrc={START}
    title="Cum deschidem un container folosind telefonul sau cardul fizic?"
    buttonLink="/salut/second"
    buttonText="Start"
    description="Am pregatit un mic ghid cum poti sa deschizi containerul folosind cardul primit de la primarie sau telefonul mobil."
  ></TutorialLayout>
)

export default Start
